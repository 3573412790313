.App {
    padding: 15px;
    background-color: var(--highlight-bg);
    height: 100vh;
    text-align: center;
}

.qotd-box-shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
